import { createApp } from 'vue'
import App from './App.vue'

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import i18n from '@/core/plugins/i18n'
import VueGtag from 'vue-gtag'
import VuePlyr from 'vue-plyr'

//imports for app initialization
import ApiService from '@/core/services/ApiService'
import { initApexCharts } from '@/core/plugins/apexcharts'
import { initInlineSvg } from '@/core/plugins/inline-svg'
import { initVeeValidate } from '@/core/plugins/vee-validate'
import { initVueSelect } from '@/core/plugins/vue-select'
import Socketio from '@/core/plugins/Socket.io'

import '@/core/plugins/keenthemes'
import '@/core/plugins/prismjs'
import 'bootstrap'
import 'vue-plyr/dist/vue-plyr.css'
import 'vue3-emoji-picker/dist/style.css'
const app = createApp(App)

app.use(store)
app.use(router)
app.use(ElementPlus)

app.use(Socketio, {
  connection: process.env.VUE_APP_API_URL,
  options: {
    transports: ['websocket'],
    path: process.env.VUE_APP_SOCKET_PATH,
    upgrade: false,
  },
})
app.use(
  VueGtag,
  {
    config: {
      id: 'G-N6VDR0SEPD',
      params: {
        send_page_view: true,
      },
    },
    enabled: process.env.VUE_APP_GOOGLE_ANALYTICS_ENABLED == 'true',
  },
  router
)

app.use(VuePlyr, {
  plyr: {},
})

ApiService.init(app)
initApexCharts(app)
initInlineSvg(app)
initVeeValidate()
initVueSelect(app)

app.use(i18n)

app.mount('#app')
