/* eslint-disable */
import ApiService from '@/core/services/ApiService'
import JwtService from '@/core/services/JwtService'
import router from '@/router'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators'

export interface Employee {
  photo: string
}

export interface User {
  employee: Employee
  classRooms: Array<any>
  token: string
  photo: string
  _id: string
  role: string[]
}

export interface UserAuthInfo {
  error: string
  user: User
  isAuthenticated: boolean
  activeSchoolarYear: string
}
export interface ServerConfig {
  base_url?: string
  dbName: string
  building: object
}
@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  error = ''
  user = {} as User
  activeSchoolarYear: string = ''
  isAuthenticated = !!JwtService.getToken()
  updatedProfileAlert = false
  isTeacher = false
  isAdvisor = false
  advisorAddExercice = false
  buildingDB = 'Root'
  trimester = 1 as Number
  canSwitchToTeacher = false
  buildingConfig = {
    teacherParentChat: true,
  }
  isSwitchedToTeacher = false
  advisor = false

  serverConfig = {} as ServerConfig
  /**
   * Get base_url
   * @returns string
   */
  get serverConfigUrl(): ServerConfig {
    return this.serverConfig
  }
  /**
   * Get trimester
   * @returns Number
   */
  get currentTrimester(): Number {
    return this.trimester
  }
  /**
   * Get current building db
   * @returns String
   */
  get currentBuildingDB(): string {
    return this.buildingDB
  }

  @Mutation
  [Mutations.SET_SERVER_CONFIG](serverConfig) {
    this.serverConfig = serverConfig
  }

  @Mutation
  [Mutations.SET_TRIMESTER](trimester) {
    this.trimester = trimester
  }

  @Mutation
  [Mutations.SET_BUILDING_DB](db: string) {
    this.buildingDB = db
  }

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user
  }
  /**
   * Get if the advisor can create exercice
   * @returns User
   */
  get advisorCanAddExercice(): Boolean {
    return this.advisorAddExercice
  }
  /**
   * Get current user object
   * @returns schoolar year name
   */
  get currentSY(): string {
    return this.activeSchoolarYear
  }
  @Mutation
  [Mutations.SET_SY](sy) {
    this.activeSchoolarYear = sy
  }
  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated
  }

  get isUpdatedProfileAlert(): boolean {
    return this.updatedProfileAlert
  }

  /**
   * Get current user photo
   * @returns User
   */

  get userPhotoURL(): string {
    if (this.user.employee && this.user.employee.photo)
      return (
        this.serverConfigUrl.base_url +
        '/' +
        this.user.employee.photo.replaceAll('\\', '/')
      )
    return 'media/avatars/blank.png'
  }

  /**
   * Get authentification error
   * @returns array
   */
  get getError(): string {
    return this.error
  }

  /**
   * Verify has teacher access
   * @returns boolean
   */
  get hasTeacherAccess(): boolean {
    return this.isTeacher
  }

  /**
   * Verify has advisor access
   * @returns boolean
   */
  get hasAdvisorAccess(): boolean {
    return this.isAdvisor
  }

  get teacherSwitcherValue(): boolean {
    return this.isSwitchedToTeacher
  }

  get canSwitchToTeacherValue(): boolean {
    return this.canSwitchToTeacher
  }
  get buildingConfigValue(): object {
    return this.buildingConfig
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.error = error
  }

  @Mutation
  [Mutations.SET_PHOTO](photo) {
    this.user.employee.photo = photo
  }

  @Mutation
  [Mutations.SET_UPDATED_PROFILE_ALERT](value) {
    this.updatedProfileAlert = value
  }

  @Mutation
  [Mutations.SWITCH_TO_TEACHER]() {
    this.isTeacher = true
    this.isAdvisor = false
    this.isSwitchedToTeacher = true
  }

  @Mutation
  [Mutations.SWITCH_TO_ADVISOR]() {
    this.isTeacher = false
    this.isAdvisor = true
    this.isSwitchedToTeacher = false
  }

  @Mutation
  [Mutations.SET_AUTH]({ user, verify = false }) {
    return (async () => {
      this.isAuthenticated = true
      this.user = user.info
      if (verify && user.buildingConfig) {
        this.buildingConfig = user.buildingConfig
      }
      if (!verify) {
        this.isAdvisor = this.user.role.includes('advisor')
        this.advisor = this.user.role.includes('advisor')
        this.isTeacher = this.user.role.includes('teacher')
      }
      this.error = ''
      JwtService.saveToken(user.token)
      ApiService.setHeader()

      if (!verify)
        if (this.user.classRooms[0])
          window.localStorage.setItem(
            'activeSchoolarYear',
            this.user.classRooms[0].schoolarYear._id
          )
        else {
          await ApiService.post('/schoolaryears/filter', {
            query: {},
            aggregation: [
              {
                $sort: {
                  end: -1,
                },
              },
              {
                $project: {
                  _id: 1,
                },
              },
              {
                $limit: 1,
              },
            ],
          }).then((res) => {
            window.localStorage.setItem('activeSchoolarYear', res.data[0]._id)
          })
        }
      if (this.advisor) {
        ApiService.post('/schedules/filter', {
          query: {
            teacher: this.user._id,
            status: { $ne: 'inactive' },
            hidden: { $ne: true },
          },
          aggregation: [
            {
              $project: {
                _id: 1,
              },
            },
          ],
        }).then(({ data }) => {
          if (data.length > 0) {
            this.canSwitchToTeacher = true
          }
        })
      }
    })()
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false
    this.isAdvisor = false
    this.isTeacher = false
    this.canSwitchToTeacher = false
    this.isSwitchedToTeacher = false
    this.buildingConfig = {
      teacherParentChat: true,
    }
    this.user = {} as User
    this.error = ''
    JwtService.destroyToken()
    JwtService.destroySchoolarYear()
    JwtService.destroyDb()
    ApiService.clearHeader()
    router.push('/sign-in')
  }

  @Action
  [Actions.LOGIN](credentials) {
    /* JwtService.saveDb(credentials.schoolName);
     ApiService.setHeaderBuilding(JwtService.getBuilding() as string);
     this.context.commit(Mutations.SET_BUILDING_DB, credentials.schoolName);
 
     delete credentials.schoolName; */
    return new Promise<void>((resolve, reject) => {
      ApiService.post('/teachers/login', credentials)
        .then(async ({ data }) => {
          await this.context.commit(Mutations.SET_AUTH, { user: data })

          resolve(data)
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_ERROR, 'error')
          reject()
        })
    })
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH)
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('registration', credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, { user: data })
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error.message)
          reject()
        })
    })
  }

  // @Action
  // [Actions.SET_PHOTO](credentials) {
  //   if (JwtService.getToken()) {
  //     ApiService.patch(`/teachers/${this.user._id}/photo`, credentials)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_PHOTO, data.imageURL);
  //       })
  //       .catch(() => {
  //         this.context.commit(Mutations.SET_ERROR, "error");
  //       });
  //   } else {
  //     this.context.commit(Mutations.PURGE_AUTH);
  //   }
  // }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('/teachers/forgotpassword', payload)
        .then(() => {
          resolve()
        })
        .catch((e) => {
          this.context.commit(Mutations.SET_ERROR, 'error')
          reject()
        })
    })
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader()
      //const building = JwtService.getBuilding() as string;
      //console.log(building)
      //  ApiService.setHeaderBuilding(building);
      // this.context.commit(Mutations.SET_BUILDING_DB, building);

      ApiService.get('/teachers/verify')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, { user: data, verify: true })
        })
        .catch((res) => {
          this.context.commit(Mutations.SET_ERROR, res.data ? res.data.errors : "Erreur de vérification du compte !")
          this.context.commit(Mutations.SET_ERROR)
          this.context.commit(Mutations.PURGE_AUTH)
        })
    } else {
      this.context.commit(Mutations.PURGE_AUTH)
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader()
    return new Promise<void>((resolve, reject) => {
      ApiService.patch(`/teachers/${this.user._id}`, { data: payload })
        .then(() => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error.message)
          reject()
        })
    })
  }

  @Action
  [Actions.UPDATE_PASSWORD](payload) {
    ApiService.setHeader()
    return new Promise<void>((resolve, reject) => {
      ApiService.patch(`/teachers/changepassword/${this.user._id}`, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error.message)
          reject()
        })
    })
  }

  @Action
  [Actions.UPDATE_EMAIL](payload) {
    ApiService.setHeader()
    return new Promise<void>((resolve, reject) => {
      ApiService.patch(`/teachers/changeusername/${this.user._id}`, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error.message)
          reject()
        })
    })
  }
}
