import io from "socket.io-client";

export default {
  install: (app, { connection, options }) => {
    console.log(io);
    const socket = io(connection, options);

    socket.on("connect", () => {
      console.log("socket connected", socket.id);
    });
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
      console.log(err);
    });

    app.config.globalProperties.$socket = socket;

    app.provide("socket", socket);
  },
};
