const ID_TOKEN_KEY = "id_token" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description get trimester form localStorage
 */
export const getTrimester = (): string | null => {
  return window.localStorage.getItem("trimester");
};

/**
 * @description get building db form localStorage
 */
export const getBuilding = (): string | null => {
  return window.localStorage.getItem("db");
};

/**
 * @description get schoolar year form localStorage
 */
export const getSchoolarYear = (): string | null => {
  return window.localStorage.getItem("activeSchoolarYear");
};

/**
 * @description save activeSchoolarYear into localStorage
 * @param id: string
 */
export const saveSchoolarYear = (id: string): void => {
  window.localStorage.setItem("activeSchoolarYear", id);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description save db into localStorage
 * @param db: string
 */
export const saveDb = (db: string): void => {
  window.localStorage.setItem("db", db);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

/**
 * @description remove db form localStorage
 */
export const destroyDb = (): void => {
  window.localStorage.removeItem("db");
};

/**
 * @description remove db form localStorage
 */
export const destroySchoolarYear = (): void => {
  window.localStorage.removeItem("activeSchoolarYear");
};

export default {
  getToken,
  getSchoolarYear,
  saveToken,
  saveSchoolarYear,
  destroyToken,
  destroySchoolarYear,
  saveDb,
  destroyDb,
  getBuilding,
  getTrimester,
};
