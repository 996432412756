
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import JsPDF from "jspdf";
import Amiri from "raw-loader!@/assets/fonts/Amiri.txt";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    console.info("setting up base url: ", store.getters.serverConfigUrl);
    ApiService.setHeaderBuilding(store.getters.serverConfigUrl.dbName);

    // eslint-disable-next-line
    let callAddFont: any = function (this: any) {
      this.addFileToVFS("Amiri-normal.ttf", Amiri);
      this.addFont("Amiri-normal.ttf", "Amiri", "normal");
    };
    JsPDF.API.events.push(["addFonts", callAddFont]);

    if (store.getters.serverConfigUrl?.base_url) {
      ApiService.setBaseUrl(store.getters.serverConfigUrl.base_url);
    } else ApiService.setBaseUrl(process.env.VUE_APP_API_URL);
    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });
  },
});
